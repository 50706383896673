import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    Box,
    Typography,
    Breadcrumbs,
    Link,
    makeStyles,
} from '@material-ui/core'
import { capitalize } from 'lodash'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { useLocation } from 'react-router-dom'
import { SUBMENU_CAPITAL } from 'src/constants'

const useStyles = makeStyles((theme) => ({
    root: { margin: '10px' },
}))

const BreadCrumbs = ({ className, ...rest }) => {
    const classes = useStyles()

    const location = useLocation()
    const locationSubPath = location.pathname.split('/')
    const isBasePath = location.pathname === '/'

    return (
        <Box className={clsx(classes.root, className)} {...rest}>
            {!isBasePath && (
                <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                >
                    <Link color="inherit" to="/" component={RouterLink}>
                        Home
                    </Link>
                    {locationSubPath
                        .filter((key) => !!key)
                        .map((entry, index, array) => {
                            let modifiedIndex = index + 1
                            const isLeafPath = modifiedIndex < array.length
                            return !isLeafPath ? (
                                <Typography color="textPrimary" key={index}>
                                    {SUBMENU_CAPITAL.lastIndexOf(entry) !== -1
                                        ? entry
                                        : capitalize(entry).replace(
                                              /Services/gi,
                                              'Service Requests'
                                          )}
                                </Typography>
                            ) : (
                                <Link
                                    key={index}
                                    color="inherit"
                                    to={`/${entry}`}
                                    component={RouterLink}
                                >
                                    {SUBMENU_CAPITAL.lastIndexOf(entry) !== -1
                                        ? entry
                                        : capitalize(entry).replace(
                                              /Services/gi,
                                              'Service Requests'
                                          )}
                                </Link>
                            )
                        })}
                </Breadcrumbs>
            )}
        </Box>
    )
}

BreadCrumbs.propTypes = {
    orderedEndpoints: PropTypes.array,
}

export default BreadCrumbs
